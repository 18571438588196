import { Ref } from "vue";

const isDesktopFlyoutOpened: Ref<boolean> = ref(false);
const isNavigationBarOpened: Ref<boolean> = ref(false);
const isMobileDrawerOpened: Ref<boolean> = ref(false);

export const useHeader = () => {
  return {
    isDesktopFlyoutOpened,
    isNavigationBarOpened,
    isMobileDrawerOpened,
  };
};
